import React, { useState, useContext, useMemo, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFolder,
  faShareAlt,
  faStar,
  faLock,
  faBug,
  faLockOpen,
  faCog,
  faSpinner,
  faBan,
} from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from 'styled-components';

import ApplicationIcon from 'components/ApplicationIcon';
import { Hover, HoverText } from 'components/Hover';
import { Acesso, AcessosList, AcessoHover, Value } from 'components/Acessos';
import ListingModal from 'components/ListingModal';
import {
  Card,
  ListingItem,
  ApplicationValue,
  ApplicationName,
  CloudValue,
  ApplicationSubName,
} from 'components/Listing/styles';

import kbToMb from 'utils/kbToMb';
import formatCloudDisplay from 'utils/formatCloudDisplay';
import { getValue } from 'utils/getValue';

import { SpamBadge } from 'pages/Emails/EmailListing/EmailCard/styles';
import { Label, Status } from 'pages/Performance/WebsiteCard/styles';
import { WebsiteListingContext } from '../_context/state';
import { Hosted } from './styles';

const WebsiteCard = ({ website }) => {
  const { updateWebsite, updateField } = useContext(WebsiteListingContext);
  const theme = useContext(ThemeContext);

  const [showAccess, setShowAccess] = useState(false);
  const [modal, toggleModal] = useState(false);
  const [data, setData] = useState(null);
  const [type, setType] = useState('ftp');
  const [loading, setLoading] = useState(false);

  const hasCertificate = useMemo(() => {
    return !!website.certificate;
  }, [website]);

  const isActive = useMemo(() => {
    return website.certificate?.is_active;
  }, [website]);

  const canActivate = useMemo(() => {
    return website.is_hosted;
  }, [website]);

  const pendingActivate = useMemo(() => {
    return !(
      website.certificate?.status === 2 && website.certificate?.is_active
    );
  }, [website]);

  const cloudDisplay = useMemo(() => {
    return formatCloudDisplay(website.cloud.display);
  }, [website]);

  const debug = useMemo(() => {
    return getValue(website, 'debug');
  }, [website]);

  const use_cdn = useMemo(() => {
    return getValue(website, 'use_cdn') === 'true';
  }, [website]);

  const pagespeed = useMemo(() => {
    return getValue(website, 'pagespeed') === 'true';
  }, [website]);

  const openModal = useCallback(
    (e, type, data) => {
      e.stopPropagation();
      e.preventDefault();
      setData(data);
      setType(type);
      toggleModal(!modal);
    },
    [modal],
  );

  return (
    <ListingItem disable={website.is_disabled}>
      {modal && (
        <ListingModal
          show={data && modal}
          setShow={() => toggleModal(false)}
          type={type}
          data={data}
          action={updateWebsite}
          updateField={updateField}
        />
      )}
      <Link to={`/websites/${website.id}`}>
        <Card>
          <Row>
            <Col xs={showAccess ? '4' : '10'} md="6" xl="4">
              <ApplicationValue>
                <ApplicationIcon
                  width="30px"
                  height="30px"
                  icon={`${website.type.slug}`}
                  disable={website.is_disabled}
                />
                {website.is_hosted ? (
                  <Hover>
                    <Hosted active />
                    <HoverText
                      style={{
                        top: '16px',
                      }}
                    >
                      DNS apontado com sucesso
                    </HoverText>
                  </Hover>
                ) : null}
                {!showAccess && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <ApplicationName>
                      {website.domain ? website.domain : website.name}
                      {website.has_spammer && (
                        <SpamBadge>
                          <FontAwesomeIcon icon={faBan} />
                          SPAM
                        </SpamBadge>
                      )}
                    </ApplicationName>
                    <ApplicationSubName className="subname">
                      {website.type.name}
                    </ApplicationSubName>
                  </div>
                )}
              </ApplicationValue>
            </Col>

            <Col className="d-none d-lg-flex" lg="3">
              <ApplicationValue
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <Hover>
                  <Value>
                    <Status
                      standby={!website.cloud.is_turbo}
                      active={use_cdn}
                    />
                    <Label>CDN</Label>
                  </Value>{' '}
                  <HoverText
                    style={{
                      bottom: 20,
                      width: 370,
                    }}
                    standby={!website.cloud.is_turbo}
                    active={use_cdn}
                  >
                    {use_cdn ? (
                      <>
                        <p>CDN ativo</p>
                        Garantida uma maior performance aos usuários do seu
                        website, APIs, ou aplicação web e mobile no Brasil.
                      </>
                    ) : (
                      <>
                        <p>CDN desabilitado</p>
                        Ative o CDN para garantir maior performance aos usuários
                        do seu website, APIs, ou aplicação web e mobile no
                        Brasil.
                      </>
                    )}
                  </HoverText>
                </Hover>

                <Hover>
                  <Value>
                    <Status
                      standby={!website.cloud.is_turbo}
                      active={pagespeed}
                    />
                    <Label>PageSpeed</Label>
                  </Value>{' '}
                  <HoverText
                    style={{
                      bottom: 20,
                      width: 370,
                    }}
                    standby={!website.cloud.is_turbo}
                    active={pagespeed}
                  >
                    {pagespeed ? (
                      <>
                        <p>PageSpeed ativo</p>
                        Melhor o desempenho e otimização do seu website conforme
                        recomendações do Google.
                      </>
                    ) : (
                      <>
                        <p>PageSpeed desabilitado</p>
                        Ative o PageSpeed e melhore o desempenho e otimize seu
                        website conforme recomendações do Google.
                      </>
                    )}
                  </HoverText>
                </Hover>
                <Value>
                  <Label />
                </Value>
              </ApplicationValue>
            </Col>

            <Col className="d-none d-xl-flex" xl="2">
              <ApplicationValue style={{ fontWeight: 'normal' }}>
                <CloudValue>
                  <span className="subname">{cloudDisplay[0]}</span>
                  <span
                    className="subname"
                    style={{
                      fontSize: `${10}px`,
                    }}
                  >
                    {cloudDisplay[1]}
                  </span>
                </CloudValue>
              </ApplicationValue>
            </Col>

            <Col className="d-none d-xl-flex">
              <ApplicationValue>
                <p style={{ fontWeight: 'normal' }}>
                  {website.disk_usage ? kbToMb(website.disk_usage) : '0 B'}
                </p>
              </ApplicationValue>
            </Col>

            {!showAccess && (
              <Col className="d-sm-flex d-md-none">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    style={{
                      color: '#64728C',
                    }}
                    onClick={e => {
                      e.preventDefault();
                      setShowAccess(true);
                    }}
                  />
                </div>
              </Col>
            )}

            <Col
              xl="2"
              lg="3"
              md="6"
              xs="8"
              className={!showAccess && 'd-none d-md-flex'}
              onClick={e => {
                e.preventDefault();
                setShowAccess(false);
              }}
              style={{
                background: showAccess && theme.interface3,
                height: '100%',
                width: '100%',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
              }}
            >
              <AcessosList
                disable={website.is_disabled}
                showAccess={showAccess}
              >
                <Acesso
                  active
                  hover
                  onClick={e => openModal(e, 'ftp', website)}
                >
                  <FontAwesomeIcon icon={faFolder} />
                  <AcessoHover>
                    <Value>
                      <span>Acesso</span>
                    </Value>
                  </AcessoHover>
                </Acesso>

                <Acesso
                  active
                  fixed={debug === 'true'}
                  hover
                  onClick={e => debug && openModal(e, 'debug', website)}
                >
                  <FontAwesomeIcon icon={faBug} />
                  <AcessoHover>
                    <Value>
                      <span>
                        Debug
                        {!debug && ' not allowed'}
                      </span>
                    </Value>
                  </AcessoHover>
                </Acesso>

                <Acesso
                  active
                  fixed={
                    website.certificate &&
                    website.certificate.is_active === true
                  }
                  hover
                  onClick={e =>
                    openModal(
                      e,
                      !hasCertificate && !canActivate
                        ? 'no-https'
                        : !hasCertificate && canActivate
                        ? 'https-create'
                        : hasCertificate && pendingActivate
                        ? 'https-loading'
                        : hasCertificate && !pendingActivate && isActive
                        ? 'https-remove'
                        : null,
                      website,
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={website.certificate ? faLock : faLockOpen}
                  />
                  {hasCertificate && pendingActivate && (
                    <div
                      style={{
                        position: 'absolute',
                        right: '0px',
                        bottom: '-6px',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        size="xs"
                        style={{
                          color: theme.brand_primary_color,
                          fontWeight: 'bold',
                        }}
                      />
                    </div>
                  )}
                  <AcessoHover>
                    <Value>
                      <span>HTTPS</span>
                    </Value>
                  </AcessoHover>
                </Acesso>

                <Acesso
                  active
                  fixed={!!website.shared_users.length}
                  hover
                  onClick={e => openModal(e, 'share', website)}
                >
                  <FontAwesomeIcon icon={faShareAlt} />
                  <AcessoHover>
                    <Value>
                      <span>Share</span>
                    </Value>
                  </AcessoHover>
                </Acesso>

                <Acesso active fixed={website.is_pinned} hover favorite>
                  <FontAwesomeIcon
                    onClick={async e => {
                      setLoading(true);
                      e.preventDefault();
                      e.stopPropagation();

                      await updateWebsite(
                        'is_pinned',
                        !website.is_pinned,
                        website.id,
                      );
                      setLoading(false);
                    }}
                    icon={loading ? faSpinner : faStar}
                    spin={loading}
                  />
                  <AcessoHover>
                    <Value>
                      <span>Favorite</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
              </AcessosList>
            </Col>
          </Row>
        </Card>
      </Link>
    </ListingItem>
  );
};

export default WebsiteCard;
